import { useEffect, useState } from "react";

function App() {
  const [version, setEnvList] = useState(["has no data"]);
  const [logs, setLogs] = useState([]);
  const [selected, setSelected] = useState("test");
  const [fetchDisabled, setFetchDisabled] = useState(true);

  const handler = async (event) => {
    const val = event.target.value;
    setSelected(val);
  };

  var http_base = "https://api.mm-config-manager.mildmania.org/";

  if (window.location.hostname === "localhost")
    http_base = "http://localhost:6699/";

  const fetchMMEnvConfig = async () => {
    setFetchDisabled(false);
    const requestOptions = {
      mode: "no-cors",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ v: selected }),
    };
    await fetch(http_base + "update-mm-env", requestOptions)
      .then((response) => {
        return response.json().then(data => setLogs(data))
      })
      .catch((error) => {
        console.log("error", error);
        window.location.reload();
      });
  };

  useEffect(() => {
    const getEnvList = async () => {
      const response = await fetch(http_base + "mm-env-list");
      const data = await response.json();
      setEnvList(data);
    }
    getEnvList();
  }, [http_base]);

  useEffect(() => {
    const getLogs = async () => {
      const response = await fetch(http_base + "logs");
      const data = await response.json();
      setLogs(data);
    }
    getLogs()
  }, [http_base]);

  const options = [];

  version.forEach((log) => {
    options.push({ value: log, label: log });
  });

  return (
    <div className="App">
      <h2>Mayor Match Config Process Logs</h2>
      <hr></hr>
      <select value={selected} onChange={handler}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {fetchDisabled ? (
        <button onClick={fetchMMEnvConfig} onChange={(event) => handler(event)}>
          Fetch
        </button>
      ) : (
        <button disabled className="disable">Wait</button>
      )}
      {logs ? (
        <div className="card">
          <ul>
            {logs.map((log, i) => (
              <li key={i}>{log.message}</li>
            ))}
          </ul>
        </div>
      ) : (<div className="loading">Loading...</div>)}
    </div>
  );
}

export default App;
